<template>
  <div class="home">
    <myheader></myheader>
    <myservice></myservice>
    <el-carousel class="carouselBox" :loop="true" :interval="5000">
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <el-image v-if="!item.url || item.url == '#'" style="height: 100%;" :src="imgPath + item.value"
          fit="cover"></el-image>
        <a v-else :href="item.url" target="_blank" style="height: 100%;">
          <el-image style="height: 100%;" :src="imgPath + item.value" fit="cover"></el-image>
        </a>
      </el-carousel-item>
      <el-image :src="imgPath + banner[0].value" class="indexBanner"></el-image>
    </el-carousel>
    <div class="indexMain">
      <div class="numBox flex fWrap w maAuto">
        <div class="numTitle flex fCol acen jcen">
          <p>数字慈善</p>
          <p>E-Philanthropy</p>
        </div>
        <div class="numItem flex acen jcen" v-for="(item, index) in numList" :key="index">
          <div class="numDiv flex fCol afirst jcen">
            <p>
              <countTo :endVal='item.num'></countTo>{{ item.danwei }}
            </p>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="dynamicBox commonBoxPadding w maAuto">
        <commonTitle cn="公益动态" en="Trends in public welfare"></commonTitle>
        <el-row :gutter="40">
          <el-col :span="12" :xs="24">
            <div class="dtLeft">
              <div class="leftTitle flex jbet acen">
                <div class="dt_t_l flex">
                  <div class="t_item" v-for="item in indexDtList" :key="item.id"
                    :class="{ active: dtleftIndex == item.id }" @mouseover="mouseover(item.id)">{{ item.name }}</div>
                </div>
                <router-link :to="{ name: 'list', query: { id: 2 } }" class="dt_t_r">
                  more+
                </router-link>
              </div>
              <div class="leftContent">
                <template v-if="dtLeftList.length > 0" v-for="(item, index) in dtLeftList">
                  <a :href="item.wxPubUrl" class="c_item flex jbet" v-if="item.isWxPub == 1" target="_blank" :key="index">
                    <div class="title">
                      {{ item.title }}
                    </div>
                    <div class="time">{{ item.createTime.split(' ')[0] }}</div>
                  </a>
                  <router-link v-else :to="{ name: 'detail', query: { id: item.id } }" class="c_item flex jbet"
                    :key="index">
                    <div class="title">
                      {{ item.title }}
                    </div>
                    <div class="time">{{ item.createTime.split(' ')[0] }}</div>
                  </router-link>
                </template>
                <el-empty v-else></el-empty>

              </div>
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="dtRight">
              <div class="rightTitle flex aend">
                <div class="cn">实时捐款动态</div>
                <div class="en fontW">Real-time donations</div>
              </div>
              <div class="rightContent">
                <vue-seamless-scroll v-if="dtRightList.length > 0" :data="dtRightList" class="list"
                  :class-option="{ step: 0.5 }">
                  <div class="c_item" v-for="(item, index) in dtRightList" :key="index">
                    <div class="title">
                      {{ item.nickName }}，{{ item.jkContent }}
                    </div>
                  </div>
                </vue-seamless-scroll>
                <el-empty v-else></el-empty>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="menuBox commonBoxPadding">
        <div class="menu w maAuto flex acen jcen">
          <router-link :to="{ name: 'detail', query: { id: 1316 } }" class="menuitem flex fCol acen jcen">
            <img src="@/assets/zjjz.png" alt="">
            <div>资金捐赠</div>
          </router-link>
          <router-link :to="{ name: 'detail', query: { id: 1319 } }" class="menuitem flex fCol acen jcen">
            <img src="@/assets/zjsq.png" alt="">
            <div>基金申请</div>
          </router-link>
          <router-link :to="{ name: 'detail', query: { id: 1317 } }" class="menuitem flex fCol acen jcen">
            <img src="@/assets/wxyxy.png" alt="">
            <div>微心愿许愿</div>
          </router-link>
        </div>
      </div>
      <div class="infoBox commonBoxPadding">
        <div class="infoMain w maAuto">
          <commonTitle cn="信息公开" en="Open information" :active='infoActive' :list="indexInfoList" @changTab="changTab">
          </commonTitle>
          <el-row :gutter="100" v-if="infoList.length > 0">
            <el-col :span="12" :xs="24" v-for="(item, index) in infoList" :key="index">
              <a :href="item.wxPubUrl" class="infoItem flex" v-if="item.isWxPub == 1" target="_blank">
                <div class="time">
                  <div class="M_D">
                    {{ item.createTime.split(' ')[0].split('-')[1] }}-{{ item.createTime.split(' ')[0].split('-')[2] }}
                  </div>
                  <div class="year">{{ item.createTime.split(' ')[0].split('-')[0] }}</div>
                </div>
                <div class="info">
                  <div class="title line1">{{ item.title }}</div>
                  <div class="content line1">{{ item.source }}</div>
                </div>
              </a>
              <router-link v-else class="infoItem flex" :to="{ name: 'detail', query: { id: item.id } }">
                <div class="time">
                  <div class="M_D">
                    {{ item.createTime.split(' ')[0].split('-')[1] }}-{{ item.createTime.split(' ')[0].split('-')[2] }}
                  </div>
                  <div class="year">{{ item.createTime.split(' ')[0].split('-')[0] }}</div>
                </div>
                <div class="info">
                  <div class="title line1">{{ item.title }}</div>
                  <div class="content line1">{{ item.source }}</div>
                </div>
              </router-link>
            </el-col>
          </el-row>
          <el-empty v-else></el-empty>
        </div>
      </div>
      <div class="projectBox commonBoxPadding">
        <div class="project w maAuto" v-loading="projectLoading">
          <commonTitle cn="公益项目" en="Public Welfare Project" :active='projectActive' :list="indexProjectList"
            @changTab="changTab"></commonTitle>
          <el-row :gutter="15" v-if="projectList.length > 0">
            <el-col :span="6" :xs="12" v-for="item in projectList" :key="item.id">
              <div class="projectItem" @click="openDrawer(item)">
                <img :src="item.logo" alt="">
                <div class="title line2">{{ item.title }}</div>
              </div>
            </el-col>
          </el-row>
          <el-empty v-else></el-empty>
        </div>
      </div>
      <div class="bannerBox flex acen jcen">
        <router-link :to="{ name: 'detail', query: { id: 1318 } }">福i公益 消费慈善公益数字化平台</router-link>
      </div>
      <div class="fundBox commonBoxPadding">
        <div class="fund w maAuto">
          <commonTitle cn="社区基金" en="Community Fund" :list="indexFundList" :active='fundActive' @changTab="changTab">
          </commonTitle>
          <el-row :gutter="20" v-if="fundList.length > 0">
            <el-col :span="8" :xs="12" v-for="item in fundList" :key="item.id">
              <a :href="item.wxPubUrl" class="fundItem" v-if="item.isWxPub == 1" target="_blank">
                <img :src="imgPath + item.img" alt="">
                <div class="title">{{ item.title }}</div>
              </a>
              <router-link v-else :to="{ name: 'detail', query: { id: item.id } }" class="fundItem">
                <img :src="imgPath + item.img" alt="">
                <div class="title">{{ item.title }}</div>
              </router-link>
            </el-col>
          </el-row>
          <el-empty v-else></el-empty>
        </div>
      </div>
      <div class="policyBox commonBoxPadding">
        <div class="policy w maAuto">
          <commonTitle cn="政策法规" en="Policies and regulations" :type="2" :list="indexPolicyList" :active='policyActive'
            @changTab="changTab"></commonTitle>
          <el-row :gutter="60" v-if="policyList.length > 0">
            <el-col :span="12" :xs="24" v-for="item in policyList" :key="item.id">
              <a :href="item.wxPubUrl" class="policyItem flex acen jbet" v-if="item.isWxPub == 1" target="_blank">
                <div class="title line1">{{ item.title }}</div>
                <div class="time">{{ item.createTime ? item.createTime.slice(0,10) : '' }}</div>
              </a>
              <router-link v-else :to="{ name: 'detail', query: { id: item.id } }" class="policyItem flex acen jbet">
                <div class="title line1">{{ item.title }}</div>
                <div class="time">{{ item.createTime ? item.createTime.slice(0,10) : '' }}</div>
              </router-link>
            </el-col>
          </el-row>
          <el-empty v-else></el-empty>
        </div>
      </div>
      <div class="linkBox commonBoxPadding">
        <div class="link w maAuto">
          <commonTitle cn="友情链接" en="link"></commonTitle>
          <vue-seamless-scroll v-if="linkList.length > 0" :data="linkList" class="list"
            :class-option="{ step: 0.5, direction: 2 }">
            <div class="listBox flex acen">
              <a :href="item.linkUrl" target="_blank" class="linkItem flex acen jcen" v-for="item in linkList"
                :key="item.id">
                <img :src="imgPath + item.img" alt="">
              </a>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="aboutBox commonBoxPadding">
        <div class="about w maAuto">
          <el-row :gutter="10">
            <el-col :span="14" class="hidden-sm-and-down">
              <el-row :gutter="10" type="flex" justify="space-between">
                <el-col v-for="(item) in allMenuList" :key="item.id" v-if="item.status == 1 && item.footerM == 1">
                  <div class="aboutItem">
                    <div class="title">{{ item.name }}</div>
                    <div class="item alink" @click="goLink(son)" v-for="son in item.childrenList"
                      v-if="son.status == 1 && son.footerM == 1" :key="son.id">{{ son.name }}
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="4" :xs="24">
              <div class="aboutItem">
                <div class="title">深圳市福田区慈善会捐款帐户</div>
                <div class="item">开户单位：深圳市福田区慈善会</div>
                <div class="item">开户银行：民生银行皇岗支行</div>
                <div class="item">银行账号：1821014400000022</div>
                <div class="item">信用代码：51440304676669951E</div>
                <div class="item">捐款热线：0755-83921118</div>
                <div class="item">邮箱：ftcsh@189.cn</div>
              </div>
            </el-col>
            <el-col :span="3" :xs="12">
              <div class="ewm flex fCol acen">
                <img src="../assets/ewm.png" alt="">
                <div>福田区慈善会</div>
              </div>
            </el-col>
            <el-col :span="3" :xs="12">
              <div class="ewm flex fCol acen">
                <img src="../assets/xcxewm.jpg" alt="">
                <div>福i公益</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <myfooter></myfooter>
    <wxyDrawer ref="wxyDrawer"></wxyDrawer>
    <proDrawer ref="proDrawer"></proDrawer>

  </div>
</template>

<script>
import { getArticlePage, getBannerList, getLinkList, getCharitableCount, getDonationDynamicsPage, findProjectListByTypePage, findWxyList, findProjectDesc, findWxyDesc } from '@/api/index'
import {
  mapGetters,
  mapActions
} from "vuex";
export default {
  name: 'HomeView',
  computed: {
    ...mapGetters(['allMenuList', 'indexInfoList', 'indexProjectList', 'indexFundList', 'indexPolicyList', 'indexDtList']),
  },
  data() {
    return {
      imgPath: process.env.VUE_APP_IMAGE_URL,
      banner: [],
      linkList: [],
      numList: [{
        title: '累计受益人次',
        num: 0,
        danwei: '人次'
      }, {
        title: '社区基金',
        num: 0,
        danwei: '个'
      }, {
        title: '冠名基金',
        num: 0,
        danwei: '个'
      }, {
        title: '公益项目',
        num: 0,
        danwei: '个'
      }, {
        title: '微心愿',
        num: 0,
        danwei: '个'
      }, {
        title: '爱心企业',
        num: 0,
        danwei: '家'
      }, {
        title: '捐款累计参与人次',
        num: 0,
        danwei: '人次'
      }],
      dtleftIndex: 1,
      dtLeftList: [],
      dtRightList: [],
      infoList: [],
      infoActive: 0,
      projectActive: 0,
      projectList: [],
      fundActive: 0,
      fundList: [],
      policyActive: 0,
      policyList: [],
      projectLoading: false,
      scrollTop: 0
    }
  },
  watch: {
    allMenuList: {
      handler: function () {
        this.infoActive = this.indexInfoList[0]?.id
        this.projectActive = this.indexProjectList[0]?.id
        this.fundActive = this.indexFundList[0]?.id
        this.policyActive = this.indexPolicyList[0]?.id
        this.dtleftIndex = this.indexDtList ? this.indexDtList[0]?.id : 0
        this.init()
      },
      deep: true,
      immediate: true
    },
  },
  created() {
    this.getBannerList()
    this.getLinkList()
    this.getCharitableCount()
    this.getDonationDynamicsPage()
  },
  //在页面离开时记录滚动位置
  beforeRouteLeave(to, from, next) {
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    next()
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log('scrollTop :>> ', vm.scrollTop, vm);
      document.body.scrollTop = vm.scrollTop
    })
  },

  methods: {
    getCharitableCount() {
      getCharitableCount().then(res => {
        this.numList[0].num = res.data.ljsyrcCount
        this.numList[1].num = res.data.sqjjCount
        this.numList[2].num = res.data.gmjjCount
        this.numList[3].num = res.data.gyProjectCount
        this.numList[4].num = res.data.wxyProjectCount
        this.numList[5].num = res.data.axCompanyCount
        this.numList[6].num = res.data.jkljcyrcCount

      })
    },
    getDonationDynamicsPage() {
      getDonationDynamicsPage({ page: 1, pageSize: 100 }).then(res => {
        this.dtRightList = res.data.content
      })
    },
    getBannerList() {
      getBannerList({ page: 1, pageSize: 20 }).then(res => {
        this.banner = res.data.content.filter(item => item.status == 1)
      })
    },
    getLinkList() {
      getLinkList({ page: 1, pageSize: 20 }).then(res => {
        let linkList = res.data.content.filter(item => item.status == 1)
        linkList.forEach(item => {
          item.sonLinkList.forEach(son => {
            this.linkList.push(son)
          })
        })
      })
    },
    goLink(item) {
      if (item.id == 41) {
        return this.$router.push({ name: 'donationForm' })
      } else if (item.id == 43) {
        return this.$router.push({ name: 'expendForm' })
      } else if (item.id == 70 || item.id == 45) {
        return this.$router.push({ name: 'projectlist', query: { type: item.id == 70 ? 1 : 2 } })
      } else if (e == 100) {
        return this.$router.push({ name: 'message' })
      }
      if (item.linkType == 3) {
        this.$router.push({ name: 'detail', query: { id: item.articleId } })
      } else if (item.linkType == 2) {
        this.$router.push({ name: 'imglist', query: { id: item.id } })
      } else {
        this.$router.push({ name: 'list', query: { id: item.id } })
      }
    },
    openDrawer(item) {
      if (this.projectActive == 70) {
        this.$refs.proDrawer.info = {}
        this.$refs.proDrawer.ewmimg = ''
        this.$refs.proDrawer.detailDrawer = true
        this.$refs.proDrawer.loading = true
        findProjectDesc({ id: item.id }).then(res => {
          this.$refs.proDrawer.info = res.data
          this.$refs.proDrawer.info.projectBudget = JSON.parse(this.$refs.proDrawer.info.projectBudget)
          this.$refs.proDrawer.loading = false
        }).catch(res => {
          this.$refs.proDrawer.detailDrawer = false
          this.$refs.proDrawer.loading = false
        })
      } else if (this.projectActive == 45) {
        this.$refs.wxyDrawer.info = {}
        this.$refs.wxyDrawer.ewmimg = ''
        this.$refs.wxyDrawer.detailDrawer = true
        this.$refs.wxyDrawer.loading = true
        findWxyDesc({ id: item.id }).then(res => {
          this.$refs.wxyDrawer.info = res.data
          this.$refs.wxyDrawer.loading = false

        }).catch(res => {
          this.$refs.wxyDrawer.detailDrawer = false
          this.$refs.wxyDrawer.loading = false
        })

      }
    },
    init(type) {
      // 信息列表
      getArticlePage({ cateTId: this.infoActive, page: 1, pageSize: 6 }).then(res => {
        this.infoList = res.data.content
      })
      // 项目列表
      if (this.projectActive == 70) {
        this.projectLoading = true
        findProjectListByTypePage({ page: 1, pageSize: 8, isJuanKuan: 0, isRecommend: 0, isYiGong: 0 }).then(res => {
          this.projectList = res.data.content
          this.projectLoading = false
        }).catch(res => {
          this.projectLoading = false
        })
      } else if (this.projectActive == 45) {
        this.projectLoading = true
        findWxyList({ page: 1, pageSize: 8 }).then(res => {
          this.projectList = res.data.content
          this.projectLoading = false
        }).catch(res => {
          this.projectLoading = false
        })
      }
      // 基金列表
      getArticlePage({ cateTId: this.fundActive, page: 1, pageSize: 6 }).then(res => {
        this.fundList = res.data.content
      })
      // 政策列表
      getArticlePage({ cateTId: this.policyActive, page: 1, pageSize: 16 }).then(res => {
        this.policyList = res.data.content
      })
      // 政策列表
      getArticlePage({ cateTId: this.dtleftIndex, page: 1, pageSize: 8 }).then(res => {
        this.dtLeftList = res.data.content
      })
    },
    mouseover(id) {
      if (this.dtleftIndex == id) return
      this.dtleftIndex = id
      this.init()
    },
    changTab(tab) {
      if (tab.type == 'info') {
        if (this.infoActive == tab.id) return
        this.infoActive = tab.id
      } else if (tab.type == 'project') {
        if (this.projectActive == tab.id) return
        this.projectActive = tab.id
      } else if (tab.type == 'fund') {
        if (this.fundActive == tab.id) return
        this.fundActive = tab.id
      } else if (tab.type == 'policy') {
        if (this.policyActive == tab.id) return
        this.policyActive = tab.id
      }
      this.init()
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: absolute;
  background-image: none !important;
  top: 0;
  left: 0;
  z-index: 101;

  background-color: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
  -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;

  /deep/ .el-input__inner {
    color: #000;
  }
}

.indexBanner {
  width: 100%;
  opacity: 0;
  min-height: 80vh;
}

.carouselBox {
  /deep/ .el-carousel__container {
    height: auto;
  }
}


.indexMain {
  position: relative;
  z-index: 100;
  margin-top: -150px;
}

.numBox {
  padding: 20px;
  background: #FC1527;
  border-radius: 70px 0px 70px 0px;
  color: #fff;

  .numTitle {
    width: 25%;
    height: 130px;
    font-weight: 500;
    font-size: 45px;
    border-right: 1px solid rgba(255, 255, 255, .35);
    border-bottom: 1px solid rgba(255, 255, 255, .35);


    p {
      &:last-child {
        font-size: 27px;
        // text-transform: uppercase
      }
    }
  }

  .numItem {
    width: 25%;
    height: 130px;
    border-right: 1px solid rgba(255, 255, 255, .35);
    border-bottom: 1px solid rgba(255, 255, 255, .35);

    // &:hover {
    //   p {
    //     span {
    //       font-size: 58px;

    //     }
    //   }
    // }

    &:nth-child(4),
    &:nth-child(8) {
      border-right: none;

    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      border-bottom: none;

    }

    p {
      font-weight: 400;
      font-size: 16px;
      position: relative;

      &:last-child {
        &::before {
          content: '';
          position: absolute;
          bottom: -10px;
          width: 19px;
          height: 4px;
          background: #fff;

        }
      }

      span {
        font-weight: 500;
        font-size: 48px;
      }
    }
  }
}

.dynamicBox {

  .dtLeft {
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #eee;

    &:hover {
      box-shadow: 0px 1px 32px 0px rgba(35, 24, 21, 0.13);
    }

    .leftTitle {
      border-bottom: 2px solid #FC1527;

      .dt_t_l {
        .t_item {
          margin-right: 7px;
          padding: 8px 20px;
          font-weight: 400;
          font-size: 18px;
          color: #666666;
          border-radius: 5px 5px 0px 0px;
          border: 1px solid #BFBFBF;
          border-bottom: none;
          cursor: pointer;

          &:hover {
            background: #FC1527;
            border: 1px solid #FC1527;
            border-bottom: none;
            color: #FFFFFF;
          }

          &.active {
            background: #FC1527;
            border: 1px solid #FC1527;
            border-bottom: none;
            color: #FFFFFF;
          }
        }
      }

      .dt_t_r {
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .leftContent {
      margin: 20px 0;
      height: 450px;

      .c_item {
        border-bottom: 1px solid #eee;
        padding: 15px 0;
        cursor: pointer;

        &:hover {
          .title {
            color: #FC1527;
          }
        }

        .title {
          padding-left: 20px;
          position: relative;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background: #FC1527;
            border-radius: 50%;
          }
        }

        .time {
          font-weight: 400;
          font-size: 16px;
          white-space: nowrap;
          color: #666;
        }
      }
    }
  }

  .dtRight {
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #eee;

    &:hover {
      box-shadow: 0px 1px 32px 0px rgba(35, 24, 21, 0.13);
    }

    .rightTitle {
      border-bottom: 2px solid #FC1527;
      padding: 8px 0;

      .cn {
        font-weight: 400;
        font-size: 20px;
        color: #FF0000;
      }

      .en {
        font-size: 16px;
        color: #666666;
        margin-left: 10px;
      }
    }

    .rightContent {
      margin: 20px 0;
      height: 450px;
      overflow: hidden;


      .list {
        // height: 415px;
      }

      .c_item {
        padding: 15px 0 15px 20px;
        cursor: pointer;

        &:hover {
          .title {
            color: #FC1527;
          }
        }

        .title {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:nth-child(2n) {
          background: #eee;
        }
      }
    }
  }
}

.menuBox {
  background: url(../assets/menubg.jpg) no-repeat;
  background-size: 100% 100%;

  .menu {
    .menuitem {
      height: 330px;
      flex: 1;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 0px;
        background: rgba(252, 21, 39, .4);
        transition: all .3s;
      }

      img {
        max-height: 66px;
        max-width: 64px;
      }

      div {
        margin-top: 20px;
        font-size: 24px;
        color: #FFFFFF;
      }

      &:nth-child(1) {
        background: url(../assets/1.png) no-repeat;
        background-size: 100% 100%;

      }

      &:nth-child(2) {
        background: url(../assets/2.png) no-repeat;
        background-size: 100% 100%;

      }

      &:nth-child(3) {
        background: url(../assets/3.png) no-repeat;
        background-size: 100% 100%;

      }

      &:hover {
        &::before {
          height: 100%;
        }
      }
    }
  }
}

.infoBox {
  background: url(../assets/infoBg.jpg) no-repeat;
  background-size: 100% 100%;

  .infoMain {
    .infoItem {
      padding: 30px;
      background: #fff;
      border: 1px solid #EEEEEE;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 32px 0px rgba(35, 24, 21, 0.13);

        .time {
          .M_D {
            color: #FC1527;
          }

          .year {
            color: #FC1527;
          }
        }

        .info {

          .title {
            color: #FC1527;
          }

          .content {
            color: #FC1527;
          }
        }
      }

      .time {
        width: 20%;
        flex-shrink: 0;

        .M_D {
          font-weight: 700;
          font-size: 20px;
          color: #333333;
        }

        .year {
          font-size: 14px;
          color: #999999;
        }
      }

      .info {
        flex: 1;
        width: 0;

        .title {
          font-weight: 700;
          font-size: 16px;
          color: #333333;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .content {
          font-size: 14px;
          height: 20px;
          color: #999999;
          line-height: 18px;
        }
      }
    }
  }
}

.projectBox {

  .project {
    .projectItem {
      display: block;
      border: 1px solid #F3F3F3;
      margin-bottom: 40px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 32px 0px rgba(35, 24, 21, 0.25);

        .title {
          color: #FC1527;
        }
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        display: block;
      }

      .title {
        color: #333333;
        height: 64px;
        padding: 20px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}

.bannerBox {
  height: 410px;
  background: url(../assets/bannergg.jpg) no-repeat;
  background-size: 100% 100%;

  a {
    font-size: 54px;
    color: #FFFFFF;

    &:hover {
      color: #FC1527;
    }
  }

  .title {
    font-size: 54px;
    margin-right: 50px;
  }
}

.fundBox {

  .fund {
    .fundItem {
      border: 1px solid #F3F3F3;
      margin-bottom: 40px;
      height: 276px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 32px 0px rgba(35, 24, 21, 0.25);

        .title {
          color: #FC1527;
          height: 100%;
        }

        img {
          transform: scale(1.1);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 48px;
        line-height: 48px;
        padding: 0 20px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, .5);
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
      }
    }
  }
}

.policyBox {
  background: #F3F3F3;

  .policy {
    .policyItem {
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        .title {
          color: #FC1527;
        }

        .time {
          color: #FC1527;
        }
      }

      .title {
        font-size: 14px;
        color: #666666;
        position: relative;
        padding-left: 20px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background: #FC1527;
          border-radius: 50%;
        }
      }

      .time {
        white-space: nowrap;
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

.linkBox {
  .list {
    overflow: hidden;
  }

  .linkItem {
    border: 1px solid #F3F3F3;
    margin-bottom: 20px;
    height: 88px;
    width: 200px;
    flex-shrink: 0;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      width: 100%;
    }
  }
}

.aboutBox {
  background: url(../assets/aboutBg.jpg) no-repeat;
  background-size: 100% 100%;

  .about {
    .aboutItem {
      color: #fff;

      .title {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .item {
        font-size: 12px;
        margin-bottom: 10px;

      }

      .alink {
        color: #fff;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: #FC1527;
        }
      }
    }

    .ewm {
      img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 30px;
      }

      div {
        text-align: center;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    position: fixed;
  }

  .indexBanner {
    min-height: auto;
  }

  .indexMain {
    margin-top: 0px;
  }

  .numBox {
    padding: 5px !important;
    border-radius: 0;

    .numTitle {
      width: 25%;
      height: 70px;
      font-size: 16px;

      p {
        &:last-child {
          font-size: 10px;
          text-transform: uppercase
        }
      }
    }

    .numItem {
      width: 25%;
      height: 70px;

      p {
        font-size: 12px;

        &:last-child {
          font-size: 10px;

          &::before {
            height: 2px;
          }
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .dynamicBox {

    .dtLeft {
      padding: 10px;
      margin-bottom: 10px;

      .leftTitle {
        .dt_t_l {
          .t_item {
            margin-right: 3px;
            padding: 4px 10px;
            font-size: 12px;
          }
        }

        .dt_t_r {
          font-size: 12px;
        }
      }

      .leftContent {
        padding: 10px 0;

        .c_item {
          padding: 6px 0;

          .title {
            padding-left: 14px;
            font-size: 12px;

            &::before {
              width: 4px;
              height: 4px;
            }
          }

          .time {
            font-size: 12px;
          }
        }
      }
    }

    .dtRight {
      padding: 10px;

      .rightTitle {
        padding: 8px 0;

        .cn {
          font-size: 14px;
        }

        .en {
          font-size: 12px;
          margin-left: 4px;
        }
      }

      .rightContent {
        padding: 10px 0;

        .c_item {
          padding: 6px 0 6px 14px;

          .title {
            font-size: 12px;
          }

          &:nth-child(2n) {
            background: #eee;
          }
        }
      }
    }
  }

  .menuBox {
    .menu {
      .menuitem {
        height: 100px;

        img {
          max-height: 40px;
          max-width: 40px;
        }

        div {
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }
  }

  .infoBox {
    .infoMain {
      .infoItem {
        padding: 10px;
        margin-bottom: 10px;

        .time {
          width: 20%;


          .M_D {
            font-size: 16px;
          }

          .year {
            font-size: 12px;
          }
        }

        .info {
          .title {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
          }

          .content {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }

  .projectBox {

    .project {
      .projectItem {
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 120px;
        }

        .title {
          height: 40px;
          padding: 10px;
          font-size: 12px;
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }

  .bannerBox {
    height: 100px;

    a {
      font-size: 16px;
    }

    .title {
      font-size: 16px;
      margin-right: 20px;
    }
  }

  .fundBox {
    .fund {
      .fundItem {
        margin-bottom: 20px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        .title {
          height: 30px;
          line-height: 30px;
          padding: 0 10px;
          box-sizing: border-box;
          font-size: 12px;
        }
      }
    }
  }

  .policyBox {
    .policy {
      .policyItem {

        .title {
          font-size: 12px;
          padding-left: 14x;

          &::before {
            width: 4px;
            height: 4px;
          }
        }

        .time {
          font-size: 12px;
        }
      }
    }
  }

  .linkBox {

    .linkItem {
      margin-bottom: 10px;
      height: 60px;
      width: 150px;
    }
  }

  .aboutBox {
    .about {
      .aboutItem {
        text-align: center;

        .title {
          font-size: 14px;
          margin-bottom: 10px;
        }

        .item {
          font-size: 12px;
          margin-bottom: 6px;

        }
      }

      .ewm {
        margin-top: 20px;

        img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 10px;
          margin-bottom: 20px;
        }

        div {
          text-align: center;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
}
</style>
