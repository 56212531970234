import { render, staticRenderFns } from "./wxyDrawer.vue?vue&type=template&id=3d11d24d&scoped=true"
import script from "./wxyDrawer.vue?vue&type=script&lang=js"
export * from "./wxyDrawer.vue?vue&type=script&lang=js"
import style0 from "./wxyDrawer.vue?vue&type=style&index=0&id=3d11d24d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d11d24d",
  null
  
)

export default component.exports