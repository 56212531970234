<template>
    <div>
        <div class="serviceBox" @click="openService">
            <img src="../assets/codeImg.png" alt="">
        </div>
        <el-dialog title="扫码与福小慈交谈" :visible.sync="centerDialogVisible" custom-class="myDialog" center>
            <span slot="title" class="dialog-title">
                扫码与<span class="red">福小慈</span>交谈
            </span>
            <div class="flex acen jcen">
                <img src="https://ssdt.oss-cn-shenzhen.aliyuncs.com/uploads/20250313/1c3ca03760544b13b351ec0b10bf49af.jpg"
                    alt="">
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'service',
    data() {
        return {
            centerDialogVisible: false
        }
    },
    methods: {
        openService() {
            this.centerDialogVisible = true;
        }
    }
}
</script>

<style lang="less" scoped>
.serviceBox {
    // 固定在页面的右下角
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 200px;
    height: 200px;
    z-index: 999;

    // 灰色阴影
    img {
        width: 150px;
        height: 150px;
        // box-shadow: 0 0 10px rgba(0,0,0,0.3);
    }
}
.red{
    color:#fc1527;
    font-weight: 900;
}


@media screen and (max-width: 750px) {
    .serviceBox {
        // 固定在页面的右下角
        position: fixed;
        bottom: 50px;
        right: 30px;
        width: 60px;
        height: 60px;
        z-index: 999;

        // 灰色阴影
        img {
            width: 60px;
            height: 60px;
            // box-shadow: 0 0 10px rgba(0,0,0,0.3);
        }
    }
}
</style>