<template>
    <el-drawer :visible.sync="detailDrawer" ref="drawer" :size="450" :with-header="false">
        <div v-loading="loading">
            <video v-if="info.videoUrl" class="myVideo" :src="info.videoUrl" autoplay object-fit="cover" controls></video>
            <img v-else-if="info.logoList" :src="info.logoList[0]" class="myLogo" />
            <div class="contentBox">
                <div class="user-box flex">
                    <div class="userRight">
                        <div class="usertitle">
                            {{ info.title || '' }}
                        </div>
                        <div class="userNeed">
                            {{ info.sketch || '' }}
                        </div>
                        <div class="tags flex acen fWrap">
                            <el-tag v-if="info.status == 0" size="mini">待启动</el-tag>
                            <el-tag v-if="info.status == 1" type="danger" size="mini">进行中</el-tag>
                            <el-tag v-if="info.status == 2" type="success" size="mini">已完成</el-tag>
                            <el-tag v-if="info.status == 3" type="warning" size="mini">已取消</el-tag>
                            <el-tag plain size="mini" type="danger">{{ info.microWishName || '' }}</el-tag>
                        </div>
                    </div>
                    <img v-if="info.status == 2" class="successImg"
                        src="https://ssdt.oss-cn-shenzhen.aliyuncs.com/uploadImages/wxySuccess.png" />
                </div>
                <div class="itemInfo flex">
                    <span>许愿人：</span>
                    <span>{{ info.beneficiary || '' }}</span>
                </div>
                <div class="itemInfo">
                    <span>基本情况：</span>
                    <div>{{ info.content }}</div>
                </div>
                <div class="itemInfo flex">
                    <span>来源：</span>
                    <span>{{ (info.provinceName || '') + (info.cityName || '')
                        + (info.areaName || '') + (info.streetName || '') + (info.communityName || '') + info.address
                    }}</span>
                </div>
                <div class="itemInfo flex">
                    <span>立项时间：</span>
                    <span>{{ info.projectApprovalTime || '' }}</span>
                </div>
                <div class="itemInfo flex" v-if="info.fundraisingCode">
                    <span>募捐编号：</span>
                    <span style="margin-right: 8px;">{{ info.fundraisingCode }}</span>
                </div>
                <div class="itemInfo flex" v-if="info.projectCode">
                    <span>心愿编号：</span>
                    <span>{{ info.projectCode }}</span>
                </div>
                <div class="itemInfo flex">
                    <span>收款机构：</span>
                    <span>{{ info.receivingInstitution || '' }}</span>
                </div>
                <div class="itemInfo flex">
                    <span>执行机构：</span>
                    <span>{{ info.executeInstitution || '' }}</span>
                </div>
                <div class="userItem flex acen">
                    <img :src="info.accountImage" mode="widthFix" />
                    <div class="userInfo">
                        <div class="name">
                            <span>{{ info.accountName || '' }}</span><span>{{ info.accountRole || '' }}</span>
                        </div>
                        <div class="pos">
                            {{ info.accountInstitution || '' }}
                        </div>
                    </div>
                </div>
                <div class="itemInfo flex acen">
                    <span>目标金额：</span>
                    <div>
                        <span>{{ info.totalRaisedAmount }}</span>元
                    </div>
                </div>
                <div class="itemInfo flex acen">
                    <span>已筹款金额：</span>
                    <div>
                        <span>{{ info.raisedAmount }}</span>元
                    </div>
                </div>
                <div class="itemInfo" v-if="info.pictureUrlList.length > 0">
                    <div style="margin-top: 15px;">
                        <img :src="item" v-for="(item, index) in info.pictureUrlList" :key="index" />
                    </div>
                </div>
            </div>
            <div @click="lookEwm" v-loading="ewmLoading" v-if="!ewmimg" class="lookEwm">查看二维码</div>
            <div v-else class="ewmBox flex acen jcen">
                <img v-if="ewmimg" :src="ewmimg" alt="">
            </div>
            <div @click="close" class="lookEwm">返回</div>
        </div>
    </el-drawer>
</template>

<script>
import { wxyhaibao } from '@/api/index'
export default {
    name: 'wxyDrawer',
    data() {
        return {
            loading: false,
            detailDrawer: false,
            info: '',
            ewmimg: '',
            ewmLoading: false
        }
    },
    methods: {
        lookEwm() {
            this.ewmimg= ''
            this.ewmLoading = true
            wxyhaibao({ id: this.info.id }).then(res => {
                this.ewmLoading = false
                this.ewmimg = res.data.img
            }).catch(res=>{
                this.ewmLoading = false

            })
        },
        close() {
            this.$refs.drawer.closeDrawer()
        }
    }
}
</script>

<style lang="less" scoped>
.ewmBox {
    img {
        width: 80%;
    }
}

.myLogo {
    width: 100%;
}

.myVideo {
    width: 100%;
}

.lookEwm {
    background: #FA5F69;
    color: #fff;
    margin: 20px;
    border-radius: 100px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.contentBox {
    background-color: #fff;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
    box-shadow: 0px 3px 35px 0px rgba(0, 0, 0, 0.1);
    position: relative;

    .user-box {
        margin-bottom: 15px;

        // img {
        //     width: 160px;
        //     height: 160px;
        //     border-radius: 10px;
        //     margin-right: 30px;
        // }

        .successImg {
            position: absolute;
            width: 100px;
            top: 20px;
            right: 0;
            z-index: 1;
            margin-right: 0px;
        }

        .usertitle {
            font-size: 20px;
            font-weight: 700;
            color: #000000;
        }

        .userNeed {
            font-size: 17px;
            color: #FA5F69;
        }
    }

    .tags {
        margin: 10px 0;

        .el-tag {
            margin-right: 10px;
        }
    }

    .itemInfo {
        margin-bottom: 10px;

        >span {
            font-size: 15px;
            color: #666666;

            &:first-child {
                font-weight: 700;
                color: #666666;
                white-space: nowrap;
            }
        }

        div {
            font-size: 15px;
            color: #666666;

            span {
                color: #FA5F69;
            }
        }

        textarea {
            width: 100%;
            margin: 15px 0;
            font-size: 15px;
            color: #666666;
        }

        img {
            width: 100%;
            display: block;
        }
    }

    .userItem {
        margin: 20px 0;

        img {
            width: 50px;
            // height: 50px;
            border-radius: 10px;
            margin-right: 20px;
            flex-shrink: 0;
        }

        .userInfo {
            .name {
                span {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;

                    &:last-child {
                        margin-left: 10px;
                        font-size: 13px;
                        color: #8A9495;
                    }
                }
            }

            .pos {
                font-size: 14px;
                font-weight: 500;
                color: #3E4B61;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    /deep/ .el-drawer.rtl {
        width: 100% !important;
    }
}
</style>